html {
  overflow-x: hidden;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.quill-desc {
  max-width: 800px;
}
.ql-editor {
  min-height: 200px;
  max-height: 300px;
  background-color: rgb(238, 238, 238);
}
.ql-editor::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.ql-editor::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.ql-editor::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.ql-editor::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.quill-desc {
  max-width: 800px;
  word-wrap: break-word;
}
.quill-desc p {
  margin: 0;
}
.quill-desc p br {
  display: none;
}
span.ql-formats:first-of-type {
  display: none !important;
}
/*#bui2 ul[data-baseweb='menu'] li {
  display: block;
  position: relative;
}
#bui2 ul[data-baseweb='menu'] li {
  display: block;
  position: relative;
}
#bui2 ul[data-baseweb='menu'] li > div:first-of-type {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
*/
